<template>
	<div class="institutionalEntry">
		<div class="content">
            <div class="settled-header">
                <p>入驻申请</p>
            </div>
			<el-form ref="myForm" :label-position="'left'" :rules="rules" label-width="168px" :model="formData" @submit.native.prevent>
				<el-form-item label="机构类型:" required="">
					<el-select v-model="formData.enterType" size="small" style="width:100%">
						<el-option :value="13" label="资源提供者">资源提供者</el-option>
						<el-option :value="14" label="教学组织者">教学组织者</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="机构名称:" prop="name" class="hasTip">
					<el-input v-model="formData.name" size="small" placeholder="请输入机构名称"></el-input>
				</el-form-item>
				<p class="formItemTip" style="color:#999;font-size:12px;">输入的名称与提交的营业执照副本上的名称保持一致</p>

				<el-form-item label="联系人:" prop="userName">
					<el-input v-model="formData.userName" size="small" :maxlength="30" placeholder="请输入联系人姓名"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:" prop="phone" class="hasTip" style="margin-bottom:8px">
					<el-input v-model.trim="formData.phone" size="small" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<div class="authCode formItemTip">
					<el-input v-model="authCode" size="small" placeholder="请输入验证码" style="width:270px"></el-input>
					<el-button v-if="Countdown" class="getcodeBtn getcodeBtnGray" type="primary" size="small" @click="getAuthCode">{{
						Countdown + 's'
					}}</el-button>
					<el-button v-else-if="isFirstGetAuthcode" class="getcodeBtn" type="primary" size="small" @click="getAuthCode">获取验证码</el-button>
					<el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCode">重新获取</el-button>
				</div>

				<el-form-item label="地址:" class="needRequred" style="margin-bottom:10px">
					<el-row type="flex" justify="space-between">
						<el-col class="selectItem">
							<el-form-item prop="province" style="margin-bottom:0">
								<el-select v-model="formData.province" size="small" placeholder="请选择省" @change="handleProvinceChange">
									<el-option v-for="item in provinceList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col class="selectItem">
							<el-form-item prop="city" style="margin-bottom:0">
								<el-select v-model="formData.city" size="small" placeholder="请选择市" @change="handleCityChange">
									<el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col class="selectItem">
							<el-form-item prop="district" style="margin-bottom:0">
								<el-select v-model="formData.district" size="small" placeholder="请选择区">
									<el-option v-for="item in districtList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form-item>
				<div class="formItemTip">
					<el-input v-model="formData.addressDetail" size="small" :maxlength="50" placeholder="请输入详细街道地址"></el-input>
				</div>

				<el-form-item label="营业许可证编号:" prop="organCode" key="organCode">
					<el-input v-model="formData.organCode" size="small" placeholder="请输入营业许可证编号"></el-input>
				</el-form-item>
			</el-form>
			<div class="footer">
				<el-button @click="handleClose" class="cancelBtn" size="small">返 回</el-button>
				<el-button type="primary" size="small" class="submitBtn" @click="submit">提交申请</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import config from '../../../config/url'
// import { organCodeValidator } from '../../../utils/validator'
export default {
	name: 'institutionalEntry',
	computed: {
		cityList() {
			if (this.formData.province) {
				let findItem = this.provinceList.find((item) => item.code === this.formData.province)
				return findItem && findItem.children
			} else {
				return []
			}
		},
		districtList() {
			if (this.formData.city) {
				let findItem = this.cityList.find((item) => item.code === this.formData.city)
				return findItem && findItem.children
			} else {
				return []
			}
		},
	},
	data() {
		const accountValidator = (rule, value, callback) => {
			const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
			if (!isPhone.test(value)) {
				callback(new Error('请输入正确的手机号'))
			} else {
				callback()
			}
		}
		return {
			licenseImageUploadLoading: false,
			action: config.API + '/resource/v1/resource/uploadPicture',
			headers: {
				token: localStorage.getItem('token'),
			},
			authCode: '',
			provinceList: [],
			province: '',
			city: '',
			district: '',
			formData: {
				enterType: 13,
                name: '',
                userName: '',
				phone: '',
				province: '',
				city: '',
                district: '',
                addressDetail: '',
                organCode: ''
			},
			copyFormData: {},
			rules: {
				name: { required: true, message: '请输入机构名称', trigger: 'blur' },
				userName: { required: true, message: '请输入联系人姓名', trigger: 'blur' },
				province: { required: true, message: '请选择省', trigger: 'change' },
				city: { required: true, message: '请选择市', trigger: 'change' },
				district: { required: true, message: '请选择区', trigger: 'change' },
				organCode: [
					{ required: true, message: '请输入营业许可证编号', trigger: 'blur' },
					// { validator: organCodeValidator, trigger: 'blur' },
				],
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ validator: accountValidator, trigger: 'blur' },
				],
			},
			isFirstGetAuthcode: true,
			Countdown: 0,
		}
	},
	mounted() {
		let navNode = document.querySelector('.header-temp')
		let contentNode = document.querySelector('.institutionalEntry')
		let browserHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
		let navHeight = navNode ? navNode.clientHeight : 64
		let contentHeight = browserHeight - navHeight
		contentNode.style.height = contentHeight + 'px'
		// this.copyFormData = { ...this.formData }
		this.getRegionList()
	},
	methods: {
		getRegionList() {
			this.$api.usercenter.getTree({ type: 1 }).then((res) => {
				if (res.success) {
					this.provinceList = Object.freeze(res.data)
				}
			})
		},
		getAuthCode() {
			this.$refs.myForm.validateField('phone', (error) => {
				if (!error) {
					const data = { account: this.formData.phone, type: 2 }
					if (this.Countdown > 0) return
					this.Countdown = 60
					this.setCountdown()
					this.$api.usercenter.getAuthCodePublic({ data })
					this.isFirstGetAuthcode = false
				}
			})
		},
		setCountdown() {
			this.CountdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					clearInterval(this.CountdownInterval)
				}
			}, 1000)
		},
		handleProvinceChange() {
			this.formData.city = ''
			this.formData.district = ''
			this.$nextTick(() => {
				this.$refs.myForm.clearValidate(['city', 'district'])
			})
		},
		handleCityChange() {
			this.formData.district = ''
			this.$nextTick(() => {
				this.$refs.myForm.clearValidate('district')
			})
		},
		async submit() {
			try {
				await this.$refs.myForm.validate()
			} catch (error) {
				return
			}
			let data = { ...this.formData }
			data.regionCode = data.district
			data.authCode = this.authCode
			this.$api.usercenter.saveTrafficBranchApply(data).then((res) => {
				if (res.success) {
					this.$alert('审核结果将以短信通知给您，请注意查收', '已提交', {
						confirmButtonText: '确定',
						center: true,
						customClass: 'messageBox',
						confirmButtonClass: 'dialog_confirmBtn',
						callback: () => {
                            this.$router.back()
                        },
					})
				}
			})
		},
		handleClose() {
			this.$confirm('返回数据不会保存, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.$router.back()
				})
				.catch(() => {})
		},
	},
}
</script>
<style lang="stylus" scoped>
.institutionalEntry {
    background #fff
}
.settled-header{
    border-bottom:1px solid #F1F6FF;
    padding-bottom:32px;
    p{
        font-size:20px;
        padding-left:14px;
        position:relative;
        font-weight:600;
        color:#1B2257;
        &::after{
        width:4px;
        position:absolute;
        left:0;
        top:50%;
        content:'';
        height:18px;
        background:#316FFF;
        transform:translateY(-50%);
    }
    }
}
.content {
    background:#fff;
    width:1200px;
    margin:0 auto;
    padding:32px 48px 142px;
    box-sizing border-box;
    .el-form{
        width:682px;
        padding-top:32px;
        margin:0 auto;
    }
}

.footer {
    border-top:1px solid #F1F6FF;
    padding-top:30px;
    text-align:center;
    .submitBtn {
        margin-left 40px
    }
}
.messageBox{
    width auto
    .el-message-box__title span{
        font-size 18px
    }
}
.dialog_confirmBtn{
    width 120px
}
/deep/ .el-input__inner {
    color #333
}
  .needRequred {
      margin-bottom: 0;
      /deep/ .el-form-item__label::before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px
      }
  }
  .formItemTip {
      margin-left 168px
      margin-bottom 24px
  }
  /deep/ .el-form-item__error {
      position: static
  }
  /deep/ .el-form-item {
      margin-bottom 24px
      .el-form-item__content {
          line-height 32px
      }
  }
  /deep/ .el-input__inner {
      padding 0 8px
  }
  .hasTip {
      margin-bottom 4px
  }
  .authCode {
      display:flex;
      justify-content: space-between
      .getcodeBtn{
          width 98px
          border-width 0
          &.getcodeBtnGray {
              background-color #BFBFBF
              color #fff
          }
      }
  }
  .selectItem {
      width: 120px;
      /deep/ .el-input__inner {
          &::placeholder {
              color: #333333;
          }
      }
  }
</style>
